<template>
    <div class="detail"
        >
        <el-button @click="goBack"  type="primary" class="back-btn">返回</el-button>
        <!-- <el-button type="primary" class="down-btn" @click="downLoadPdf">下载</el-button> -->
        <Breadcrumb :breadcrumbdata="breadcrumbdata"></Breadcrumb>
        <div class="certificate-img" id="pdfDom">
            <iframe :height="iframeH"
                  frameborder="0"
                  width="100%"
                  id="myiframe"
                  :src="previewPdf"
                  alt=""></iframe>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'
import {baseapi} from '@/config/index.js'
// import JsPDF from 'jspdf'
export default {
    name: 'certificatedetail',
    components: {
        Breadcrumb
    },
    data() {
        return {
            isLoading:false,
            previewPdf: '',
            iframeH: '500',
            breadcrumbdata: [{
                    name: '开放平台'
                },
                {
                    name: '存证总览',
                    path: '/hashcertificate/overview'
                },
                {
                    name: '证书详情',
                    path: '',
                    isactive: true
                }
            ]
        }
    },
    computed: {},
    mounted() {
        console.log("sssssssssssssssssssssssssssssssssss",document.domain)
        const that = this;
        let winH = document.documentElement.clientHeight || document.body.clientHeight
        this.iframeH = winH - 132
        window.onresize = function() {
            let winH = document.documentElement.clientHeight || document.body.clientHeight
            this.iframeH = winH - 132
        }
        // const domain  = 'https://testwww.zhixinblockchain.com'
        // const domain = document.domain;
        // this.previewPdf = `${domain}/cert/?accessCode=${this.$route.query.accessCode}`
        // console.log("previewPdf",this.previewPdf)

        this.previewPdf = `${baseapi}/cert/?accessCode=${this.$route.query.accessCode}&isAgent=${this.$route.query.isAgent}`
        console.log("previewPdf",baseapi,this.previewPdf)
        console.log("previewPdf",this.previewPdf)
        // document.getElementById("myiframe").onload = function(){
        //    that.isLoading = false
        // };
    },
    methods: {
       goBack() {
            this.$router.go(-1)
        },
    }
}
</script>

<style lang='scss' scoped>
.detail {
    position: relative;
    .certificate-img {
        position: relative;
        // height: 1414px;
        // width: 1000px;
        // margin: 0 auto;
        // background-image: url('../../../../assets/img/certificate.png');
        // background-size: 100% auto;
    }

    .back-btn {
        // background: #385abd;
        color: #fff;
        font-size: 16px;
        position: absolute;
        top: 0px;
        right: 40px;
        cursor: pointer;
    }
    .down-btn{
        font-size: 16px;
        position: absolute;
        top: 0px;
        right: 140px;
        cursor: pointer;
    }
}
</style>
